<mat-toolbar id="main-toolbar" class="mat-toolbar-row mat-toolbar-single-row"
             [ngClass]="{'authorized': (authorized$ | async), 'not-authorized': (authorized$ | async) === false}">

  <app-tabs></app-tabs>
  <span class="spacer"></span>
  <app-menu [style.margin-right]="'1.5em'"></app-menu>

  <div fxFlexAlign="end center" #logoContainer fxLayout="row">
    <img class="card-image" alt="EU-Logo" id="EU-Logo" src="assets/logo-eu.svg"/>
    <img class="card-image" alt="Niedersachsen Logo" id="Niedersachsen-Logo" src="assets/niedersachsen-klar.jpg"/>
  </div>
</mat-toolbar>
